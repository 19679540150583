export const checkRedirect = (userInfo, navigate, redirects) => {
	const { redirectInvestor, redirectAdmin } = redirects

	if (userInfo?.role === 'investor') {
		navigate(redirectInvestor)
	} else if (
		userInfo?.role === 'editor' ||
		userInfo?.role === 'administrator' ||
		userInfo?.role === 'superadministrator'
	) {
		navigate(redirectAdmin)
	}
}
