import { makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { matchPersonType } from 'shared/matchData/matchData'
import styles from '../styles/investorRegisterTitlesStyles'

const useStyles = makeStyles(styles)

const InvestorRegisterTitles = ({ personType }) => {
  const classes = useStyles()
  return (
    <GridContainer>
      <GridItem xs={12}>
        {personType === 'none' ? (
          <h4 className={classes.fontTitles}>Seleccione un tipo de perfil.</h4>
        ) : (
          <h4 className={classes.fontTitles}>
            Con este formulario usted creará además de un usuario para la plataforma, un perfil inversor de tipo{' '}
            <b>{`${matchPersonType(personType)}`}</b> con los mismos datos.
          </h4>
        )}
      </GridItem>
    </GridContainer>
  )
}

export default InvestorRegisterTitles
