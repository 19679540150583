import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SelectInputGender from 'components/SelectInputGender/SelectInputGender'

const InputsPhysicalProfile = ({ userInfo, setUserInfo }) => {
  return (
    <GridContainer>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText={'Nombre  *'}
          id='investor-firstname'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.name,
            onChange: (e) => {
              setUserInfo({ ...userInfo, name: e.target.value })
            },
            type: 'text',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText={'Apellidos  *'}
          id='investor-lastname'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.lastName,
            onChange: (e) => {
              setUserInfo({ ...userInfo, lastName: e.target.value })
            },
            type: 'text',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText={'DNI/NIE *'}
          id='investor-identification'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.dni,
            onChange: (e) => {
              setUserInfo({ ...userInfo, nif: e.target.value, dni: e.target.value })
            },
            type: 'text',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText='Teléfono *'
          id='investor-phone'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.phone,
            onChange: (e) => {
              setUserInfo({ ...userInfo, phone: e.target.value })
            },
            type: 'text',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText='Email *'
          id='investor-email'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.email,
            onChange: (e) => {
              setUserInfo({ ...userInfo, email: e.target.value })
            },
            type: 'email',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText={'Dirección *'}
          id='investor-address'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.address,
            onChange: (e) => {
              setUserInfo({ ...userInfo, address: e.target.value })
            },
            type: 'text',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText='Provincia *'
          id='investor-province'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.province,
            onChange: (e) => {
              setUserInfo({ ...userInfo, province: e.target.value })
            },
            type: 'text',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText='Localidad *'
          id='investor-location'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.location,
            onChange: (e) => {
              setUserInfo({ ...userInfo, location: e.target.value })
            },
            type: 'text',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText='Código postal *'
          id='investor-postalCode'
          error={
            (userInfo.postalCode && userInfo.postalCode.length < 4) ||
            (userInfo.postalCode && userInfo.postalCode.length > 5)
          }
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.postalCode,
            onChange: (e) => {
              setUserInfo({ ...userInfo, postalCode: e.target.value })
            },
            type: 'number',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomInput
          labelText={'IBAN *'}
          id='investor-iban'
          helperText='Formato ES9999999999999999999999'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: userInfo.bankAccountNumber,
            onChange: (e) => {
              setUserInfo({ ...userInfo, bankAccountNumber: e.target.value })
            },
            type: 'text',
            required: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <SelectInputGender userInfo={userInfo} setUserInfo={setUserInfo} all />
      </GridItem>
    </GridContainer>
  )
}

export default InputsPhysicalProfile
